.article-card {
    // min-width: 420px;
    max-width: 420px;
}

.view-article {
    color: var(--grey);
    font-weight: 500;
    margin-left: 6px;
}

