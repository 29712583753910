.inner-contact {
    background-color: rgba(250, 250, 250, 0.8);
}

.contact {
    width: 80%;
    margin: 0 auto 30px auto;
    max-width: 640px !important;
}

a:hover {
    color: var(--lightgrey) !important;
}

.email-text {
    font-size: 24px;
    margin-bottom: 22px;
    i {
        margin-right: 8px;
    }
    &:hover {
        color: var(--lightgrey) !important;
    }
}

.contactTitleWidth {
    max-width: 540px !important;
}

@media screen and (max-width: 490px) {
    .responsiveEmail {
        font-size: 22px !important;
    }
}

@media screen and (max-width: 465px) {
    .responsiveEmail {
        font-size: 20px !important;
    }
}

@media screen and (max-width: 400px) {
    .responsiveEmail {
        font-size: 14px !important;
    }
}

@media screen and (max-width: 340px) {
    .responsiveEmail {
        font-size: 12px !important;
    }
}

@media screen and (max-width: 490px) {
    .responsivePhone {
        font-size: 22px !important;
    }
}

@media screen and (max-width: 465px) {
    .responsivePhone {
        font-size: 20px !important;
    }
}

@media screen and (max-width: 400px) {
    .responsivePhone {
        font-size: 16px !important;
    }
}

@media screen and (max-width: 340px) {
    .responsivePhone {
        font-size: 14px !important;
    }
}

@media screen and (max-width: 490px) {
    .responsiveConnect {
        font-size: 22px !important;
    }
}

@media screen and (max-width: 465px) {
    .responsiveConnect {
        font-size: 20px !important;
    }
}

@media screen and (max-width: 400px) {
    .responsiveConnect {
        font-size: 16px !important;
    }
}

@media screen and (max-width: 340px) {
    .responsiveConnect {
        font-size: 14px !important;
    }
}

@media screen and (max-width: 490px) {
    .responsiveArrow {
        font-size: 22px !important;
    }
}

@media screen and (max-width: 465px) {
    .responsiveArrow {
        font-size: 21px !important;
    }
}

@media screen and (max-width: 400px) {
    .responsiveArrow {
        font-size: 20px !important;
    }
}

@media screen and (max-width: 340px) {
    .responsiveArrow {
        font-size: 19px !important;
    }
}

.motion__wrapper {
    padding: 32px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.motion-box {
    width: 100%;
    max-width: 500px;
    height: 420px;
    border-radius: 8px;
    /* background: #fff; */
    background: linear-gradient(90deg, #00d2ff 0%, #3a47d5 100%);
}

