@media screen and (max-width: 400px) {
    .responsiveFooter {
        font-size: 22px !important;
    }
}

@media screen and (max-width: 365px) {
    .responsiveFooter {
        font-size: 21px !important;
    }
}

@media screen and (max-width: 360px) {
    .responsiveFooter {
        font-size: 20px !important;
    }
}

@media screen and (max-width: 340px) {
    .responsiveFooter {
        font-size: 19px !important;
    }
}

@media screen and (max-width: 300px) {
    .responsiveFooter {
        font-size: 18px !important;
    }
}

.footer {
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 64px;
    a {
        &:first-child {
            margin-right: 10px;
        }
        &:last-child {
            margin-left: 10px;
        }
    }
}