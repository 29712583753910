.project-card {
    min-width: 320px;
    max-width: 320px;
}

// .view-info {
//     margin: 6px auto 0 auto;
//     color: var(--grey) !important;
// }

// .view-info:hover {
//     color: var(--lightgrey);
// }

.MuiCardContent-root {
    &.project-card {
        height: 120px;
    }
    
}

.test {
    height: 500px;
    width: 500px;
}

.card-wrap {
    height: 100%;
    width: 100%;
    min-height: 600px;
}



.project-card {
    .actions {
        opacity: 0;
    }
    &:hover {
        .actions {
            opacity: 1;
        }
    }
}