@use '_shared.scss';

* {
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

html,
body {
  height: 100% !important;
  width: 100% !important;
  height: 100vh !important;
  width: 100vw !important;
  margin: 0 !important;
  padding: 0 !important;
  color: var(--black);
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

button:focus {
  outline: none;
}
