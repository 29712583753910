.project-card {
    min-width: 320px;
    max-width: 320px;
}

#projectDetail {
    padding: 48px 24px;
}

@media screen and (max-width: 400px) { 
    #projectDetail {
        padding: 28px 0px;
    }
}

// .view-info {
//     margin: 6px auto 0 auto;
//     color: var(--grey) !important;
// }

// .view-info:hover {
//     color: var(--lightgrey);
// }

.MuiCardContent-root {
    &.project-card {
        height: 120px;
    }
    
}



.login-form {
    width: 100%;
    height: 100%;
    min-height: 684px;
}

.form-wrap {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;

}

.password-form {
    display: flex;
    flex-direction: column; 
    width: 400px;
    button {
        margin-top: 24px;
    }
}

.error {
    color: red;
    font-size: 22px;
    margin-top: 12px;
}


img {
    &.project-logo {
        width: 120px;
        height: 120px;
    }
}