.Navbar,
.navbar,
nav {
	background-color: var(--black);
	width: 100vw;
}

.navbar-brand {
	font-size: 16.5px !important;
	padding-bottom: 3px !important;
}

.nav-item {
	color: var(--lightgrey);
	margin-left: auto !important;
}

.logo {
	width: 32px;
	height: 32px;
}

.nav-link {
	color: var(--white) !important;
	text-transform: uppercase !important;

	&.active {
		color: var(--medgrey) !important;
		font-weight: 900;
	}
}

// Animation
// .box {
//     width: 150px;
//     height: 150px;
//     border-radius: 50%;
//     background: var(--accent);
//     margin: 0;
//   }

.nav-menu {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	width: 280px;
	background: #000;;
	padding-top: 100px;
	transform: translateX(100%);
	will-change: transform;
	ul {
		display: flex;
		flex-direction: column;
		gap: 10px;
		padding: 15px;
	}

	li {
		color: #fff;
		display: block;
		transform-origin: -20px 50%;
		font-weight: bold;
		font-size: 48px;
		padding: 10px;
		will-change: transform, opacity, filter;
	}

	ul,
	li {
		list-style: none;
		margin: 0;
        &:hover {
            cursor: pointer;
        }
	}
}

button {
	&.menu-toggle {
		outline: none;
		border: none;
		cursor: pointer;
		position: fixed;
		top: 6px;
		right: 15px;
		width: 50px;
		height: 50px;
		border-radius: 50%;
		background: #000;
		padding: 10px;
        z-index: 1500;
        path {
            fill: #fff;
        }
	}
}

.nav-wrap {
    position: relative;
    z-index:1500;
    display: none;
}

@media (max-width: 599px) {
    .nav-wrap {
        display: block;
    }
}

