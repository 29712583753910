


h3.about-text {
    line-height: 1.4;
    font-size: 1.50rem;
    margin-bottom: 16px;
}

@media screen and (max-width: 767px) {
    h3.about-text { 
        font-size: 1.35rem;
    }
}

@media screen and (max-width: 500px) {
    h3.about-text { 
        font-size: 1.10rem;
    }
}

@media screen and (max-width: 400px) {
    h1 {
        font-size: 12vw;
    }
    h3.about-text { 
        font-size: 1.0rem;
    }
}

.title-container {
    &.about {
        padding-bottom: 14px;
    }
}

.about-container {
    margin-top:46px;
}