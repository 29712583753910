:root {
  --black: #000000;
  --white: #FFFFFF;
  --grey: #424242;
  --medgrey: #757575;
  --lightgrey: #BDBDBD;
}

.full-viewport {
  min-height: 100vh;
  width: 100vw;
  padding-bottom: 1px;
}

.dark {
  background-color: var(--black);
}

.dark-title {
  color: var(--white);
}

.light {
  background-color: var(--white);
}

.light-transparent {
  background-color: rgba(250, 250, 250, 0.8);
}

.light-title {
  color: var(--black);
}

.grey-text {
  color: var(--grey);
}

.mdgrey-text {
  color: var(--medgrey);
}

.ltgrey-text {
  color: var(--lightgrey);
}

.white-text {
  color: var(--white);
}

.black-text {
  color: var(--black);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  border-radius: .3rem;
  background-color: none;
}

.jumbo {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
}

.center-content-2 {
  margin: auto;
  padding-top: auto;
  padding-bottom: auto;
  vertical-align: middle;
}

.center-page {
  display: flex;
  text-align: center !important;
  align-content: center !important;
  min-height: 100vh;
}

.flex-page {
  height: 100%;
  display: flex;
}

.flex-page-column {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.arrow-padding-bottom {
  padding-bottom: 70px;
}

@media screen and (max-width: 400px) {
  .arrow-padding-bottom {
    padding-bottom: 40px;
  }
  .jumbo.detail {
    padding: 2rem 0rem;
    margin-bottom: 2rem;
  }
}

i:hover {
  cursor: pointer;
}

.pointer-link {
  cursor: pointer;
}

.title-container {
  padding-top: 20px;
  padding-bottom: 60px;
}

a,
a:hover,
a:active,
a:visited,
a:focus {
    text-decoration: none !important;
}

// i.fa:hover {
//   color: var(--medgrey)
// }

button,
button:hover,
button:active,
button:visited,
button:focus {
    text-decoration: none !important;
}

h1.section-title {
  font-size: 48px;
  line-height: 1.2;
  
}

@media screen and (max-width: 767px) {
  h1.section-title { 
      font-size: 36px;
  }
}

@media screen and (max-width: 500px) {
  h1.section-title { 
    font-size: 32px;
  }
}

h2.section-subtitle {
  font-size: 42px;
}

@media screen and (max-width: 767px) {
  h2.section-subtitle { 
      font-size: 36px;
  }
}

@media screen and (max-width: 500px) {
  h2.section-subtitle { 
    font-size: 30px;
  }
}

.page-bg  {
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-image: url('./assets/tl-bg.svg') !important;
  -webkit-background-size: auto 100%;
  -moz-background-size: auto 100%;
  -o-background-size: auto 100%;
  background-size: auto 100%;
  -webkit-background-repeat: no-repeat;
  -moz-background-repeat: no-repeat;
  -o-background-repeat: no-repeat;
  background-repeat: no-repeat;
  -webkit-background-position: unset;
  -moz-background-position: unset;
  -o-background-position: unset;
  background-position: unset;
  -webkit-background-attachment: scroll;
  -moz-background-attachment: scroll;
  -o-background-attachment: scroll;
  background-attachment: scroll;
}

@media screen and (min-width: 1200px) {
  .page-bg {
      position: relative;
      height: 100vh;
      width: 100vw;
      overflow: hidden;
      background-image: url('./assets/tl-bg.svg') !important;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      -webkit-background-repeat: no-repeat;
      -moz-background-repeat: no-repeat;
      -o-background-repeat: no-repeat;
      background-repeat: no-repeat;
      -webkit-background-position: center;
      -moz-background-position: center;
      -o-background-position: center;
      background-position: center;
      -webkit-background-attachment: scroll;
      -moz-background-attachment: scroll;
      -o-background-attachment: scroll;
      background-attachment: scroll;
  }
}

.arrow-icon {
  font-size: 3.5rem !important;
  &:hover {
    cursor:pointer;
    color: var(--medgrey);
  }
}

.arrow-wrap {
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.arrow-link {
  height: 40px;
  width: 24px;
}