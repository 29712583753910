.google-slides-container{
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
}

.google-slides-container iframe{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

// iframe {
//     width: 100%;
//     height: 100%;
//     min-height: 684px;
// }